var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0003286" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBL0003281",
                                    icon: "save_alt",
                                  },
                                  on: { btnClicked: _vm.btnQrCode },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.equipData,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBL0001727",
                              name: "equipmentNo",
                            },
                            model: {
                              value: _vm.equipData.equipmentNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "equipmentNo", $$v)
                              },
                              expression: "equipData.equipmentNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBL0001725",
                              name: "equipmentName",
                            },
                            model: {
                              value: _vm.equipData.equipmentName,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "equipmentName", $$v)
                              },
                              expression: "equipData.equipmentName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-equip-class", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBL0001720",
                              name: "equipmentTypeCd",
                            },
                            model: {
                              value: _vm.equipData.equipmentTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "equipmentTypeCd", $$v)
                              },
                              expression: "equipData.equipmentTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.updateMode,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.equipData.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "plantCd", $$v)
                              },
                              expression: "equipData.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-5" },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              required: true,
                              label: "LBL0001721",
                              parentCheckDepts: _vm.equipData.managementDepts,
                              plantCd: _vm.equipData.plantCd,
                              name: "managementDepts",
                            },
                            model: {
                              value: _vm.equipData.managementDepts,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "managementDepts", $$v)
                              },
                              expression: "equipData.managementDepts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-5" },
                        [
                          _c("c-process", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0001701",
                              multiple: "multiple",
                              required: true,
                              name: "subProcessCd",
                            },
                            model: {
                              value: _vm.equipData.subProcessCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "subProcessCd", $$v)
                              },
                              expression: "equipData.subProcessCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBLAMOUNT",
                              name: "amount",
                              type: "number",
                            },
                            model: {
                              value: _vm.equipData.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "amount", $$v)
                              },
                              expression: "equipData.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0003292", topClass: "topcolor-blue" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0003282",
                              name: "equipmentLocation",
                            },
                            model: {
                              value: _vm.equipData.equipmentLocation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.equipData,
                                  "equipmentLocation",
                                  $$v
                                )
                              },
                              expression: "equipData.equipmentLocation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: { label: "LBL0003293", name: "installDate" },
                            model: {
                              value: _vm.equipData.installDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "installDate", $$v)
                              },
                              expression: "equipData.installDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0003294",
                              name: "installVendor",
                            },
                            model: {
                              value: _vm.equipData.installVendor,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "installVendor", $$v)
                              },
                              expression: "equipData.installVendor",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              label: "LBL0003295",
                              name: "opertationDate",
                            },
                            model: {
                              value: _vm.equipData.opertationDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "opertationDate", $$v)
                              },
                              expression: "equipData.opertationDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0003296", topClass: "topcolor-blue" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0003297",
                              name: "checkPassNo",
                            },
                            model: {
                              value: _vm.equipData.checkPassNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "checkPassNo", $$v)
                              },
                              expression: "equipData.checkPassNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              label: "LBL0003298",
                              name: "checkPassDate",
                            },
                            model: {
                              value: _vm.equipData.checkPassDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "checkPassDate", $$v)
                              },
                              expression: "equipData.checkPassDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "LBL0001723",
                              name: "discardFlag",
                            },
                            model: {
                              value: _vm.equipData.discardFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "discardFlag", $$v)
                              },
                              expression: "equipData.discardFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                _vm.equipData.discardFlag == "Y",
                              label: "LBL0003299",
                              name: "discardDate",
                            },
                            model: {
                              value: _vm.equipData.discardDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "discardDate", $$v)
                              },
                              expression: "equipData.discardDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0003308", topClass: "topcolor-blue" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-multi-select", {
                            attrs: {
                              editable: _vm.editable,
                              isArray: false,
                              codeGroupCd: "REGULATION_BILL_API_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "LBL0003311",
                              name: "relatedLaws",
                              maxValues: "3",
                            },
                            model: {
                              value: _vm.equipData.relatedLaws,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "relatedLaws", $$v)
                              },
                              expression: "equipData.relatedLaws",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-1" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "LBL0001724",
                              name: "lawEquipmentFlag",
                            },
                            on: { datachange: _vm.datachange },
                            model: {
                              value: _vm.equipData.lawEquipmentFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "lawEquipmentFlag", $$v)
                              },
                              expression: "equipData.lawEquipmentFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-1" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: false,
                              codeGroupCd: "MDM_EQUIP_GRADE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "equipGradeCd",
                              label: "LBL0003280",
                            },
                            model: {
                              value: _vm.equipData.equipGradeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "equipGradeCd", $$v)
                              },
                              expression: "equipData.equipGradeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "LBL0003309",
                              name: "psmFlag",
                            },
                            model: {
                              value: _vm.equipData.psmFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "psmFlag", $$v)
                              },
                              expression: "equipData.psmFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-select", {
                            attrs: {
                              required: _vm.equipData.psmFlag == "Y",
                              codeGroupCd: "MDM_PSM_TYPE_CD",
                              type: "edit",
                              disabled: _vm.equipData.psmFlag !== "Y",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "psmTypeCd",
                              label: "LBL0000640",
                            },
                            model: {
                              value: _vm.equipData.psmTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "psmTypeCd", $$v)
                              },
                              expression: "equipData.psmTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "LBL0003310",
                              name: "hazardousFlag",
                            },
                            model: {
                              value: _vm.equipData.hazardousFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipData, "hazardousFlag", $$v)
                              },
                              expression: "equipData.hazardousFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }